import { mainConnect, generateError, alifpayConnect } from '@/services';

const requestQueue = {};

export const balancesService = {
  /**
   * Get balances
   */
  getBalances(params) {
    const link = '/balances';
    if (link in requestQueue) {
      return requestQueue[link];
    }
    return (requestQueue[link] = mainConnect
      .get(link, { params })
      .catch(generateError)
      .finally(() => {
        delete requestQueue[link];
      }));
  },

  /**
   * Get top-ups statuses
   */
  getTopUps(params) {
    return mainConnect.get('/alifpay/v2/purchases', { params }).catch(generateError);
  },

  /**
   * Get top-ups statuses
   */
  getTopUpsStatuses() {
    return mainConnect.get('/alifpay/v2/purchases/statuses').catch(generateError);
  },

  /**
   * Get stores services
   */
  getMerchantsStores() {
    return mainConnect.get('merchants/get-with-relations').catch(generateError);
  },

  /**
   * Get top-ups services
   */
  getTopUpServices() {
    return mainConnect.get('/alifpay/v2/purchases/services').catch(generateError);
  },

  getInvoiceByInvoiceId(id) {
    return alifpayConnect.post(process.env.VUE_APP_ALIFPAY_API + '/checkout/getInvoice', { id }).catch(generateError);
  },
  /**
   * Get ikpus by product name
   * @param {Object} params
   * @param {String} params.name - product name
   *
   * @typedef {Object} Ikpus
   * @property {String} title - product title
   * @property {Number} ikpu - product ikpu
   * @returns {Promise<Ikpus>}
   *
   * @throws {Error}
   *
   */
  getIkpusByProductName(params) {
    return mainConnect.get('/warehouse/moderation-requests/items/search', { params }).catch(generateError);
  },

  /**
   * Get categories
   * @param {Object} params
   * @param {String} params.name - categories name
   *
   * @typedef {Object} Category
   * @property {String} name - category name
   * @property {Number} id - category id
   * @property {Number} is_markable - category is_markable
   * @returns {Promise<Category>}
   *
   * @throws {Error}
   */
  getCategories(params) {
    return mainConnect.get('/warehouse/categories', { params }).catch(generateError);
  },

  /**
   * Check valid of marking code
   * @param {Object} body
   * @param {String} body.marking - mark value
   */
  checkMarkingCode(body) {
    return mainConnect.post('/applications/applications/application_items/check-marking', body).catch(generateError);
  },

  /**
   * Create invoice
   * @param {Object} body
   * @param {Array} body.items - items
   * @param {String} body.phone - phone number
   * @param {String} body.split_months - split months
   *
   */
  createInvoice(body, config) {
    return mainConnect.post('/invoice', body, config).catch(generateError);
  },
  /**
   * Cancel topup
   * @param {Object} body
   * @param {String} body.ref - id string
   * 
   * @returns {Promise<void>}

   */
  cancelTopup(body) {
    return mainConnect.post('alifpay/v2/purchases/refund', body);
  },
  /**
   * Send invoice by sms
   * @param {Object} body
   * @param {String} body.phone - phone number
   * @param {String} body.id - split months
   * 
   * @typedef {Object} SmsInvoice
   * @property {String} content - content of sms
   * @property {Number} id - merchant id
   * @property {Number} phone - phone of reciever
   * @property {String} link - link for panda-checkout
   * 
   * @returns {Promise<SmsInvoice>}

   */
  sendInvoiceBySms(body) {
    return mainConnect.post('/sendInvoice', body).catch(generateError);
  },

  /**
   * Get gnk receipt
   * @param {String} ext_ref
   *
   * @typedef {Object} GnkReceipt
   * @property {String} qr_code - url to gnk receipt
   * 
   * @returns {Promise<GnkReceipt>}

   */
  getGnkReceipt(ext_ref) {
    return mainConnect.get(`/gnk/get-receipt-by-external-ref/${ext_ref}`).catch(generateError);
  },
};
