export default {
  features: {
    addOrFindNewClient: {
      passportUpdateRequiredModal: {
        title: 'Клиент с данным номером телефона уже существует',
        desc: 'Чтобы продолжить, обновите паспортные данные клиента',
        cancel: 'Отмена',
        update: 'Изменить паспортные данные',
      },
      checkClientStatus: {
        title: 'Добавление/поиск клиента',
        fields: {
          passport: {
            placeholder: 'Серия и номер паспорта',
          },
          phone: {},
        },
        submit: 'Добавить/найти клиента',
      },
      clientExistsModal: {
        title: 'Клиент с такими данными уже существует',
        subTitle:
          'Для оформлении рассрочки требуется регистрация клиента через приложение Alif Business на мобильном телефоне',
        steps: ['Скачать приложение отсканировав QR-код', 'Введите данные клиента', 'Регистрируйте клиента через MyID'],
        footer: 'Не создавайте клиентов с поддельными паспортными данными!',
        ok: 'Понятно',
      },
      clientConfirmAgreement: {
        title: 'Согласие на обработку данных',
        smsWasSentToClient: 'СМС-код был отправлен на номер клиента ',
        fields: {
          otp: {
            label: 'Код из СМС',
          },
        },
        confirm: 'Потвердить',
      },
      createClient: {
        ensureDataAreCorrectAlert: {
          title: 'Перепроверьте данные!',
          body: 'После создания клиента, эти данные нельзя будет менять',
        },
        passport: {
          title: 'Паспорт клиента',
          uploadPhotoMessage: 'Загрузите фото пасспорта клиента',
          fields: {
            passportPhoto: {
              label: 'Фото пасспорта',
            },
          },
          continue: 'Продолжить',
        },
        idPassport: {
          title: 'ID карта',
          uploadPhotoMessage: 'Загрузите фото ID карты клиента',
          fields: {
            passportIdPhotoFront: {
              label: 'Передняя сторона ID карты',
            },
            passportIdPhotoBack: {
              label: 'Задняя сторона ID карты',
            },
          },
          submit: 'Продолжить',
        },
        passportData: {
          gender: {
            M: 'Мужчина',
            F: 'Женщина',
          },
          fields: {
            passportId: {
              label: 'Серия и номер',
            },
            pinfl: {
              label: 'ПИНФЛ',
            },
            region: {
              label: 'Регион',
            },
            address: {
              label: 'Адрес прописки',
            },
            nationalityName: {
              label: 'Гражданство',
            },
            birthplace: {
              label: 'Место рождения',
            },
            issueDate: {
              label: 'Дата выдачи',
              placeholder: 'год-месяц-день',
            },
            expiresDate: {
              label: 'Годен до',
              placeholder: 'год-месяц-день',
            },
            issuedBy: {
              label: 'Кем выдан',
              placeholder: 'Кем выдан паспорт',
            },
            name: {
              label: 'Имя',
            },
            surname: {
              label: 'Фамилия',
            },
            patronymic: {
              label: 'Отчество',
            },
            gender: {
              label: 'Пол',
            },
            birthDate: {
              label: 'Дата рождения',
            },
            cityName: {
              label: 'Место рождения',
            },
          },
          submit: 'Сохранить',
          cancel: 'Отмена',
          fetchOcr: 'Считать с фото заново',
        },
      },
      createClientOtpStep: {
        title: 'Код потверждения',
        smsWasSentToClient: 'СМС-код был отправлен на номер клиента ',
        fields: {
          otp: {
            label: 'Код из СМС',
          },
        },
        cancel: 'Отмена',
        submit: 'Потвердить',
      },
    },
  },
};
