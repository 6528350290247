export default {
  index: {
    title: 'Список оплат',
    issueInvoice: 'Выставить счет',
    dowloadCsv: 'Экспорт в excel',
    onlinePurchase: 'Онлайн касса',
    headers: {
      ref: 'Id',
      paymentVariant: 'Тип оплаты',
      holder: 'Ф.И Покупателя',
      sum: 'Сумма товара',
      date: 'Дата создания',
      updated_at: 'Дата изменения',
      status: 'Статус',
      holderePhone: 'Номер телефона',
      spic: 'ИКПУ товара',
      label: 'Название товара',
      store: 'Название магазина',
    },
    buttons: {
      reset: 'Сбросить фильтр',
      filter: 'Фильтр',
      search: 'Поиск',
      next: 'Далее',
      prev: 'Назад',
      copy: 'Скопировать',
    },
    placeholders: {
      phone: 'Номер покупателя',
      name: 'Ф.И Покупателя',
      spic: 'ИКПУ товара',
      label: 'Код маркировки товара',
      total: 'Сумма',
      paymentType: 'Тип оплаты',
      store: 'Все магазины',
      created_from: 'Дата от',
      created_to: 'Дата до',
      id: 'Поиск по ID',
      ext_id: 'Поиск по внешнему ID',
    },
    slideOver: {
      header: 'Детали транзакции',
      spic: 'ИКПУ товара',
      updated_at: 'Дата обновления',
      cancelled_at: 'Дата отмены',
      subtitle: 'Название товара',
      phoneTitle: 'Номер телефона покупателя',
      holderTitle: 'ФИО покупателя',
      label: 'Код маркировки',
      monthlyPayment: 'Ежемесячная сумма реккурентных платяжей',
      month: 'Ежемесячные платежи',
      fiscalReceipt: 'Чек',
      fiscalReceiptTitle: 'Фискальные чеки',
      splitMonthes: 'Месяц реккурентных платяжей',
      close: 'Закрыть',
      revertedBy: 'Инициатор отмены',
      revertedByAlif: 'Сотрудник Алифа',
      ext_id: 'Внешний ID',
    },
    pagination: {
      select: 'Выбрать по:',
    },
    status: {
      all: 'Все',
    },
  },
  createInvoice: {
    title: 'Выставление счета',
    aboutProducts: 'Информация о товарах',
    product: {
      name: 'Название',
      category: 'Категория',
      productByIKPU: 'Название по ИКПУ',
      ikpu: 'ИКПУ',
      code: 'Маркировка',
      total: 'Сумма',
      sum: 'сум',
      split: 'Срок',
      splitType: 'Вводить вручную',
      month: 'мес',
      instantPay: 'Оплата сразу',
    },
    placeholders: {
      spic: 'Выберите название по ИКПУ',
      category: 'Выберите категорию',
      name: 'Введите название',
    },
    errors: {
      maxAmount: 'Максимальная сумма - 200 000 000',
      minAmount: 'Минимальная сумма - 1',
      notOne: 'Нельзя передавать на 1 мес',
    },
    searchLoading: 'Загрузка...',
    firstTimeSearch: 'Введите название для поиска',
    searchEmptyResult: 'Ничего не найдено',
    monthlyPayment: 'Ежемесячная сумма оплаты:',
    payment: 'Cумма оплаты:',
    link: 'Ссылка',
    createLink: 'Создать ссылку',
    clearForm: 'Очистить поля',
    optional: 'Необязательно',
    verified: 'Проверено',
    check: 'Проверить',
    isNotChecked: 'Маркировка не проверена',
    smsInvoice: {
      title: 'Ссылка',
      submit: 'Отправить ссылку',
      label: 'Номер телефона клиетна',
    },
  },
  excel: {
    id: 'ID',
    paymentType: 'Тип оплаты',
    amount: 'Стоимость',
    date: 'Дата и время',
    status: 'Статус выплаты',
    spic: 'ИКПУ товара',
    label: 'Название товара',
    store: 'Магазин',
    phone: 'Номер покупателя',
    created_at: 'Дата и время',
    updated_at: 'Дата обновления',
    fee: 'Комиссия Эквайера',
    created_from: 'С',
    created_to: 'По',
    created: 'Отчет создан',
    excelLength: 'Кол-во транзакций',
    period: 'Период',
    filename: 'Отчеты',
  },
  cancelTransaction: {
    button: 'Отменить транзакцию',
    placeholder: 'Причина',
    submit: 'Подтвердить отмену',
    cancel: 'Отменить',
    status: 'Транзакция отменена!',
    timeout: 'Нельзя вернуть через',
    reset: 'Вернуть',
    success: 'Успешно',
    errors: {
      notFound: 'Не найдено',
      unprocessable: 'Невозможно отменить данный платеж',
      temporalError: 'Временая ошибка сервера',
      clientError: 'Ошибка сервиса',
      unknown: 'Неизвестная ошибка',
    },
  },
};
