<template>
  <div v-if="reload">
    <at-modal :model-value="reload" size="lg">
      <div class="text-center mb-6">
        <div class="mb-4 flex flex-col">
          <p class="rounded-full mx-auto justify-center items-center bg-yellow-100 dark:bg-yellow-200 p-3">
            <exclamation-icon class="text-yellow-500 dark:text-yellow-600 h-6 w-6"></exclamation-icon>
          </p>
        </div>
        <h4 class="font-bold text-lg">
          {{ $t('system.reload.siteHasBeenUpdated') }}
        </h4>
        <p class="text-base">
          {{ $t('system.reload.pleaseUpdate') }}
        </p>
      </div>
      <div class="flex flex-wrap items-center justify-evenly -m-2">
        <div class="p-2 flex flex-col items-stretch flex-grow">
          <at-button color="secondary" class="w-full" @click.prevent.stop="reloadLater()">
            {{ $t('system.reload.updateLater') }}
          </at-button>
        </div>
        <div class="p-2 flex flex-col items-stretch flex-grow">
          <at-button color="primary" class="w-full" @click.prevent.stop="reloadPage()">
            {{ $t('system.reload.updateNow') }}
          </at-button>
        </div>
      </div>
    </at-modal>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'BaseDeployReload',
  });
</script>

<script setup>
  import { computed, onUnmounted, onMounted, defineAsyncComponent, ref } from 'vue';
  import { useMainStore } from '@/modules/main/common/stores';
  import { ExclamationIcon } from '@heroicons/vue/solid';
  import centrifuge from '../../plugins/centrifuge';

  const store = useMainStore();

  const channel = process.env.VUE_APP_CENTRIFUGE_DEPLOY_CHANNEL;

  const showModal = () => {
    localStorage.setItem('reload', true);
    store.$patch({ reload: true });
  };

  const reload = computed(() => store.reload);

  const reloadPage = () => {
    if (typeof window !== 'undefined' && 'location' in window && typeof window.location.reload === 'function') {
      window.location.reload(true);
      store.$patch({ reload: false });
      localStorage.removeItem('reload');
    }
  };

  const reloadLater = () => {
    store.$patch({ reload: false });
  };

  const subscription = ref(null);

  const handleDeploy = () => {
    showModal();
  };

  onMounted(() => {
    subscription.value = centrifuge.getSubscription(channel) || centrifuge.newSubscription(channel);

    subscription.value.subscribe();

    subscription.value.on('publication', handleDeploy);
  });

  onUnmounted(() => {
    localStorage.removeItem('reload');
    subscription.value.off('publication', handleDeploy);
  });

  const AtModal = defineAsyncComponent(() => import('@/plugins/aliftech-ui/components/AtModal/AtModal'));
  const AtButton = defineAsyncComponent(() => import('@/plugins/aliftech-ui/components/AtButton/AtButton'));
</script>
