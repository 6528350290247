import { APPLICATION_STATUS } from '../constants';

export default {
  entities: {
    application: {
      table: {
        title: 'Arizalar',
        seeAll: 'Hammasini ko‘rish',
        noApplicationsAlert: 'Bugun uchun arizalar yo‘q',
        headers: ['Ariza', 'Yaratildi', 'Holat'],
        applicationItems: '0 ta mahsulot | 1 ta mahsulot | {count} ta mahsulot',
      },
      animations: {
        applicationOnGulChatAiReview: {
          title: 'Koʻrib chiqilmoqda...',
        },
        applicationIsApproved: {
          title: 'Arizani tasdiqlandi',
        },
        applicationRejected: {
          title: 'Rad etildi',
        },
        applicationOnComplianceReview: {
          subTitle: 'Ariza kredit boʻlimiga uzatildi',
        },
      },
      statusText: {
        [APPLICATION_STATUS.NEW]: 'Yangi',
        [APPLICATION_STATUS.REVIEWING]: "ko'rib chiqishda",
        [APPLICATION_STATUS.APPROVED]: 'Tasdiqlandi',
        [APPLICATION_STATUS.REJECTED]: 'rad etildi',
        [APPLICATION_STATUS.COMPLETED]: 'Rasmiylashtirildi',
        [APPLICATION_STATUS.DELETED]: 'Bekor qilindi',
        [APPLICATION_STATUS.CANCELLED]: "O'chirildi",
      },
    },
  },
};
